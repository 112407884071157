#menu.open {
	display: block;
}

header .menu-toggle {
	display: block;
}

button.menu-trigger {
	background: transparent;
	border: none;
	cursor: pointer;
	display: inline-block;
	height: 38px;
	overflow: hidden;
	/*outline: none;*/
	position: relative;
	text-indent: -900em;
	vertical-align: top;
	width: 40px;
}

button.menu-trigger span {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 3px;
	margin: 6px 0;
	background: #fff;
	display: block;
	opacity: 1;
	border-radius: 1px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-o-transition: all .25s ease-in-out;
	transition: all .25s ease-in-out;
}

button.menu-trigger.active span {
	background: #fff;
	border-radius: 2px;
}

button.menu-trigger span:first-child {
	top: 0;
}

button.menu-trigger span:nth-child(2) {
	top: 12px;
}

button.menu-trigger span:nth-child(3) {
	top: 24px;
}

button.menu-trigger.active span:first-child {
	top: -3px;
	left: 8px;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

button.menu-trigger.active span:nth-child(2) {
	opacity: 0;
	width: 0%;
}

button.menu-trigger.active span:nth-child(3) {
	top: 26px;
	left: 8px;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

#menu {
	background-color: var(--color-primary );
	display: none;
	left: 0;
	position: absolute;
	top: var(--height-header);
	width: 100%;
	z-index: 9999;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li a:not(.btn) {
			align-items: center;
			color: var( --color-light );
			display: flex;
			font-size: 120%;
			font-weight: 600;
			gap: 20px;
			padding: 2rem 2rem;

			&:hover {
				background-color: #1e355e !important;
				text-decoration: none;
			}

			img {
				height: auto;
				width: 30px;
				max-height: 25px;
			}
		}
	}
}