@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Poppins:wght@800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Rokkitt:wght@300&display=swap";
:root {
  --school-code: "dsbn";
  --width-logo: 120px;
  --height-logo: 120px;
  --height-navigation: 350px;
  --height-header: 120px;
  --padding-gap: 4rem;
  --padding-section-spacing: 6rem;
  --border-radius: 15px;
  --border-radius-small: 10px;
  --width-wrapper-main: 1200px;
  --width-wrapper-inside: 1100px;
  --width-wrapper-small: 1000px;
  --width-wrapper-medium: 1350px;
  --width-wrapper-large: 1500px;
  --font-family-primary: "Open Sans", sans-serif;
  --font-family-secondary: "Poppins", sans-serif;
  --font-family-heading: "Open Sans", sans-serif;
  --font-family-menu: "Open Sans", sans-serif;
  --font-family-footer-body: "Open Sans", sans-serif;
  --font-size-base: 16px;
  --font-size-heading-one: 4em;
  --font-size-heading-two: 2.5em;
  --font-size-heading-three: 1.5em;
  --font-size-heading-four: 1.2em;
  --font-size-heading-five: 1em;
  --font-size-paragraph-bg: 1.2em;
  --color-primary: #1e355e;
  --color-secondary: #1e355e;
  --color-overlay: #ab1d37b3;
  --color-footer: #1e355e;
  --color-dark: #000;
  --color-light: #fff;
  --color-gray: #eee;
  --color-heading: var(--color-dark);
  --color-body: var(--color-dark);
  --color-accent: var(--color-secondary);
  --color-footer-menu-text: var(--color-light);
  --color-footer-menu-text-hover: var(--color-light);
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  select {
    background: #fff !important;
  }

  .navbar {
    display: none;
  }

  .btn > .caret, .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }

  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: #0000;
  font-size: 10px;
}

body {
  color: #333;
  background-color: #fff;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a:hover, a:focus {
  color: #23527c;
  text-decoration: underline;
}

a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
  margin-top: 20px;
  margin-bottom: 20px;
}

[role="button"] {
  cursor: pointer;
}

header {
  background-color: var(--color-primary);
  color: var(--color-light);
  height: var(--height-header);
  padding: 4rem 0;
  position: relative;
}

header .wrapper {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
}

header .logo {
  z-index: 9999;
  position: absolute;
  top: -1rem;
  left: 20px;
}

header .logo a {
  display: flex;
}

header .logo a:hover {
  text-decoration: none;
}

header .logo span.logo-icon {
  height: var(--height-logo);
  max-width: 210px;
}

header .logo span.logo-icon img {
  max-height: 100%;
  display: block;
}

header .logo span.logo-title {
  padding-top: 3px;
  padding-left: 7px;
}

header .logo span.logo-title h1 {
  color: #fff;
  text-transform: uppercase;
  font-family: Rokkitt, serif;
  text-decoration: none;
}

header .logo span.logo-title h1 span {
  font-size: 3.5rem;
  line-height: 1em;
  display: block;
}

header .logo span.logo-title h1 span.french-immersion-title {
  font-size: 3.8rem;
}

header .logo span.logo-title h1 span:nth-child(2) {
  padding-left: 14px;
  font-size: 3rem;
  line-height: .9em;
}

header .logo span.logo-title h1 span.french-immersion-subtitle {
  font-size: 2rem;
  line-height: 1.2em;
}

header.active .logo {
  top: -1.5rem;
}

header.active .logo span.logo-icon {
  height: 80px;
}

header nav {
  z-index: 9999;
  justify-content: flex-end;
  align-items: center;
  gap: 4rem;
  display: flex;
  position: relative;
}

header #menu {
  display: none;
}

header .menu-toggle {
  display: block;
}

header .phone-toggle {
  color: var(--color-light);
  display: none;
}

header .phone-toggle svg {
  color: var(--color-light);
  width: 30px;
  height: 30px;
}

header .phone-toggle a {
  width: 30px;
  display: flex;
}

header .contact-info {
  gap: 1rem;
  font-size: 90%;
  display: flex;
}

header .contact-info a {
  color: var(--color-light);
}

header .button-container .btn {
  text-transform: uppercase;
  border-radius: 5px;
  margin-right: 10px;
  padding: .75rem 2rem;
  font-size: 80%;
}

header .button-container-mobile {
  justify-content: space-around;
  gap: 2rem;
  margin: 0 2rem 2rem;
  display: none;
}

header .button-container-mobile .btn {
  text-align: center;
  width: 50%;
}

header .contact-info-mobile {
  text-align: right;
  margin: 0 2rem 2rem 0;
  display: none;
}

body.WHM span.logo-icon {
  max-width: 165px;
}

#title {
  min-height: var(--height-navigation);
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

#title .wrapper {
  text-align: center;
  max-width: 870px;
}

#title.home {
  height: 80vh;
  position: relative;
}

#title .slideshow .slide.title {
  background: linear-gradient(var(--color-overlay), var(--color-overlay)), url("banner-background.f1358c38.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

#title .slideshow .title-content {
  width: 1000px;
  max-width: 100%;
  display: block;
}

#title.home .title-content {
  padding: 6rem 2rem;
}

#title.inside {
  background: linear-gradient(var(--color-overlay), var(--color-overlay)), url("banner-background.f1358c38.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#title h1.main-title {
  color: var(--color-light);
  font-size: var(--font-size-heading-one);
  font-family: var(--font-family-secondary);
  letter-spacing: 2px;
  text-align: center;
  margin: 0;
  font-weight: 800;
}

#title .title-content h2 {
  color: var(--color-light);
  font-size: calc(var(--font-size-heading-two) * .8);
  text-align: center;
  margin: 2rem 0;
}

#title .title-content .btn {
  text-transform: uppercase;
  border-radius: 5px;
  margin: 2rem 0 0;
  padding: .75rem 2rem;
  font-size: 90%;
}

#back-top {
  border-radius: var(--border-radius-small);
  opacity: 0;
  -o-transition: all .2s ease;
  visibility: hidden;
  z-index: 800;
  background-color: #00000080;
  background-image: url("top.3f41adf3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 45px;
  transition: all .2s;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

#back-top:hover {
  background-color: var(--color-primary);
}

#back-top a {
  text-align: left;
  text-indent: -9999px;
  width: 45px;
  height: 45px;
  display: block;
}

#back-top.active {
  opacity: 1;
  visibility: visible;
}

#menu.open, header .menu-toggle {
  display: block;
}

button.menu-trigger {
  cursor: pointer;
  text-indent: -900em;
  vertical-align: top;
  background: none;
  border: none;
  width: 40px;
  height: 38px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

button.menu-trigger span {
  opacity: 1;
  transform-origin: 0;
  -o-transition: all .25s ease-in-out;
  background: #fff;
  border-radius: 1px;
  width: 100%;
  height: 3px;
  margin: 6px 0;
  transition: all .25s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(0);
}

button.menu-trigger.active span {
  background: #fff;
  border-radius: 2px;
}

button.menu-trigger span:first-child {
  top: 0;
}

button.menu-trigger span:nth-child(2) {
  top: 12px;
}

button.menu-trigger span:nth-child(3) {
  top: 24px;
}

button.menu-trigger.active span:first-child {
  top: -3px;
  left: 8px;
  transform: rotate(45deg);
}

button.menu-trigger.active span:nth-child(2) {
  opacity: 0;
  width: 0%;
}

button.menu-trigger.active span:nth-child(3) {
  top: 26px;
  left: 8px;
  transform: rotate(-45deg);
}

#menu {
  background-color: var(--color-primary);
  left: 0;
  top: var(--height-header);
  z-index: 9999;
  width: 100%;
  display: none;
  position: absolute;
}

#menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#menu ul li a:not(.btn) {
  color: var(--color-light);
  align-items: center;
  gap: 20px;
  padding: 2rem;
  font-size: 120%;
  font-weight: 600;
  display: flex;
}

#menu ul li a:not(.btn):hover {
  text-decoration: none;
  background-color: #1e355e !important;
}

#menu ul li a:not(.btn) img {
  width: 30px;
  height: auto;
  max-height: 25px;
}

#cta-container {
  padding: var(--padding-section-spacing) 0;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#cta-container .heading.one, #cta-container .heading.two {
  margin: 10px 0;
}

#cta-container .wrapper {
  text-align: center;
}

#cta-container .cta-button {
  margin: 2rem 0;
}

#cta-container .btn {
  margin: 0 20px;
}

#cta-container p {
  max-width: 70%;
  margin: 16px auto;
}

#cta-container hr {
  width: 10%;
  margin: 20px auto;
}

.cta-container-alt {
  background-color: var(--color-secondary);
  color: #fff;
  text-align: left;
  width: 100%;
}

.cta-flex-container-alt {
  padding: var(--padding-section-spacing);
  background-position: calc(50% - 100px);
  background-repeat: no-repeat;
  background-size: auto 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.cta-flex-container-alt .cta-item {
  padding: 0;
}

.cta-container-alt .heading.two, .cta-container-alt .heading.three a, .cta-container-alt .heading.four a {
  color: #fff;
}

.cta-container-alt .contact-flex-item i {
  color: var(--color-accent);
}

.cta-container-alt a:not(.btn) {
  color: inherit;
}

.cta-container-alt .cta-item.contact span {
  font-size: var(--font-size-paragraph-bg);
  margin: 0 20px 0 0;
}

.btn {
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
  border-radius: var(--border-radius-small);
  color: var(--color-light);
  cursor: pointer;
  font-family: var(--font-family-primary);
  font-size: var(--font-size-base);
  letter-spacing: 0;
  text-transform: none;
  -webkit-appearance: none;
  -o-transition: all .2s ease;
  padding: 1.5rem 3rem;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
}

.btn:hover {
  border-color: var(--color-secondary);
  background-color: var(--color-secondary);
  color: var(--color-light);
  text-decoration: none;
}

.btn.secondary {
  border-color: var(--color-secondary);
  background-color: var(--color-secondary);
  color: var(--color-light);
}

.btn.secondary:hover {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-light);
}

.btn.ghost {
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: #0000;
}

.btn.ghost:hover {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-light);
}

.btn.secondary.ghost {
  border-color: var(--color-secondary);
  color: var(--color-secondary);
  background-color: #0000;
}

.btn.secondary.ghost:hover {
  border-color: var(--color-secondary);
  background-color: var(--color-secondary);
  color: var(--color-light);
}

.btn.white {
  border-color: var(--color-light);
  background-color: var(--color-light);
  color: var(--color-dark);
}

.btn.white:hover {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-light);
}

.btn.white.ghost {
  border-color: var(--color-light);
  color: var(--color-light);
  background-color: #0000;
}

.btn.white.ghost:hover {
  border-color: var(--color-light);
  background-color: var(--color-light);
  color: var(--color-primary);
}

.btn.no-rounded {
  border-radius: 0;
}

.btn-group {
  margin: 2rem 0;
}

.btn-group .btn {
  margin-top: .25rem;
  margin-bottom: .25rem;
  margin-right: .5rem;
}

.btn-group .btn:last-child {
  margin-right: 0;
}

.btn.watch {
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  font-size: 1.2rem;
  display: inline-flex;
}

.btn.watch span.play {
  aspect-ratio: 1;
  text-indent: -9999px;
  -o-transition: all .2s ease;
  background-color: #b5cddd;
  background-image: url("play.12150d1b.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30%;
  border-radius: 50%;
  width: 40px;
  margin-right: 7px;
  transition: all .2s;
}

.btn.watch:hover span.play {
  -o-transition: all .2s ease;
  margin-left: 14px;
  transition: all .2s;
}

.btn.watch span.watch {
  text-align: center;
  -o-transition: all .2s ease;
  padding: 0 2.5rem 0 2rem;
  transition: all .2s;
}

.btn.watch:hover span.watch {
  text-align: center;
  -o-transition: all .2s ease;
  padding: 0 2.5rem 0 calc(2rem - 14px);
  transition: all .2s;
}

select.form, input[type="text"].form, textarea.form, form select, form input[type="text"], form input[type="password"], form input[type="email"], form textarea, #contact-email-container select, #contact-email-container input[type="text"], #contact-email-container input[type="password"], #contact-email-container input[type="email"], #contact-email-container textarea {
  border: 1px solid var(--color-dark);
  box-sizing: border-box;
  border-radius: var(--border-radius-small);
  color: var(--color-dark);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-base);
  background: #fff;
  width: 100%;
  margin: 0;
  padding: 1.5rem 3rem;
  display: inline-block;
}

form label, #contact-email-container label {
  font-size: var(--font-size-base);
  letter-spacing: 0;
  padding-bottom: 5px;
  font-weight: bold;
  display: block;
}

form label.inline, #contact-email-container label.inline {
  display: inline-block;
}

form label.error, #contact-email-container label.error {
  color: red;
  display: block;
}

div.input, div.input-group {
  margin: 2rem 0;
}

.btn.submit {
  text-align: right;
}

.email-form .helptext {
  color: #710202;
  font-size: .8em;
}

.email-form #server-response {
  background: #eee;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 1em;
}

footer {
  background-color: var(--color-footer);
  color: var(--color-light);
  font-size: 90%;
  font-family: var(--font-family-footer-body);
  padding: var(--padding-gap) 0;
  margin-bottom: 14rem;
  line-height: 1.4em;
}

.footer-container {
  justify-content: space-around;
  align-items: flex-start;
  gap: var(--padding-gap);
  display: flex;
}

footer a {
  color: var(--color-light);
}

footer a:hover {
  color: var(--color-gray);
}

.footer-redefining-logo {
  max-width: 225px;
  display: block;
}

.footer-logo {
  background-color: #2359a8;
  width: 120px;
  padding: 17px 17px 37px;
  display: block;
  position: relative;
}

.footer-logo svg {
  fill: #fff;
  width: 77px;
  height: 104px;
  margin: 0 auto;
  display: block;
}

.footer-logo .blocks {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 20px;
  display: grid;
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-logo .blocks .block:first-of-type {
  background: #a3cf60;
}

.footer-logo .blocks .block:nth-of-type(2) {
  background: #ffc843;
}

.footer-logo .blocks .block:nth-of-type(3) {
  background: #2574bb;
}

.footer-logo .blocks .block:nth-of-type(4) {
  background: #732a81;
}

.footer-logo .blocks .block:nth-of-type(5) {
  background: #f78f1e;
}

.footer-logo .blocks .block:nth-of-type(6) {
  background: #d70036;
}

.footer-logo .blocks .block:hover {
  background: #2359a8;
}

ul.footer-menu {
  border: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: relative;
}

ul.footer-menu.uppercase li a {
  text-transform: uppercase;
}

ul.footer-menu li {
  text-align: left;
  background: none;
  height: 100%;
  margin: .5em 0;
  padding: 0;
  list-style: none;
  display: block;
  position: relative;
}

ul.footer-menu li a {
  color: var(--color-footer-menu-text);
  float: left;
  font-family: var(--font-family-menu);
  -webkit-text-decoration: uppercase;
  text-decoration: uppercase;
  text-transform: none;
  z-index: 99;
  -o-transition: all .2s ease;
  height: 100%;
  font-size: 100%;
  font-weight: normal;
  transition: all .2s;
  display: block;
  float: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

body {
  background-color: var(--color-light);
  color: var(--color-dark);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-base);
  text-align: left;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto !important;
}

iframe {
  border: none;
  width: 100%;
  max-width: 100%;
  display: block;
}

a {
  color: var(--color-dark);
  text-decoration: none;
}

a:hover {
  color: var(--color-dark);
  text-decoration: underline;
}

a.banner {
  text-decoration: none !important;
}

p {
  line-height: 1.4em;
}

hr {
  border: 0;
  border-top: 1px solid #000;
  width: 15%;
  margin: 4rem 0;
}

table {
  max-width: 100%;
}

table.hidden {
  display: none;
}

.heading, h1, h2, h3, h4, h5 {
  color: var(--color-heading);
  font-family: var(--font-family-heading);
  letter-spacing: 0;
  text-transform: none;
  margin: .75rem 0;
  font-weight: bold;
  line-height: 1.2em;
}

h1, .heading.one {
  font-size: var(--font-size-heading-one);
}

h2, .heading.two {
  font-size: var(--font-size-heading-two);
}

h3, .heading.three {
  font-size: var(--font-size-heading-three);
  font-weight: 700;
}

h4, .heading.four {
  font-size: var(--font-size-heading-four);
}

h5, .heading.five {
  font-size: var(--font-size-heading-five);
}

.heading a, h1 a, h2 a, h3 a, h4 a {
  color: inherit;
  text-decoration: none;
}

.heading em, h1 em, h2 em, h3 em, h4 em {
  color: #c6c6c6;
  font-style: normal;
}

.heading strong, h1 strong, h2 strong, h3 strong, h4 strong {
  font-weight: 800;
}

.heading.normal, h1.normal, h2.normal, h3.normal, h4.normal, p.normal {
  font-weight: 400;
}

.heading.bold, h1.bold, h2.bold, h3.bold, h4.bold, p.bold {
  font-weight: 700;
}

.heading.extra-bold, h1.extra-bold, h2.extra-bold, h3.extra-bold, h4.extra-bold, p.extra-bold {
  font-weight: 800;
}

.heading.margin-bg, h1.margin-bg, h2.margin-bg, h3.margin-bg, h4.margin-bg {
  margin: 4rem 0;
}

.italic-title {
  margin: .75rem 0;
  font-size: 120%;
  font-style: italic;
}

.subtitle {
  font-size: var(--font-size-paragraph-bg);
  margin: .75rem 0;
}

.subtitle.margin-bg, .page-title {
  margin: 2rem 0;
}

.wrapper {
  text-align: left;
  max-width: var(--width-wrapper-main);
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
}

.wrapper.small {
  max-width: var(--width-wrapper-small);
}

.wrapper.medium {
  max-width: var(--width-wrapper-medium);
}

.wrapper.large {
  max-width: var(--width-wrapper-large);
}

.wrapper.center {
  text-align: center;
}

#content {
  background-color: #fff;
  min-height: 300px;
}

#content.inside {
  background-color: #fff;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 500px;
  padding-top: 100px;
}

.content-container {
  padding: var(--padding-gap) 0;
  min-height: 50vh;
}

.page-section-container {
  padding: var(--padding-section-spacing) 0;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.page-section-container.no-padding-top {
  padding-top: 0;
}

.page-section-container.no-padding-bottom {
  padding-bottom: 0;
}

.page-section-container.padding-sm {
  --padding-section-spacing: 2rem;
}

.page-section-container.center h1, .page-section-container.center h2, .page-section-container.center h3, .page-section-container.center h4, .page-section-container.center h5, .page-section-container.center .heading {
  text-align: center;
  margin-top: 0;
}

.page-section-container.center .wrapper {
  text-align: center;
}

.page-section-container.dark {
  background-color: #000;
}

.page-section-container.white {
  background-color: #fff;
}

.page-section-container.grey {
  background-color: #f8f8f8;
}

.page-section-container.dark h1, .page-section-container.dark h2, .page-section-container.dark h3, .page-section-container.dark h4, .page-section-container.dark h5, .page-section-container.dark .heading, .page-section-container.dark p {
  color: #fff;
}

.page-section-container.dark hr {
  border-color: #fff;
}

.page-section-container.dark .subtitle {
  color: #fff;
}

#content.inside .page-section-container:first-of-type {
  padding-top: 0;
}

.breadcrumb {
  text-transform: uppercase;
}

.breadcrumb a {
  color: var(--color-dark);
}

.breadcrumb span:not(:last-child):after {
  content: ">";
  padding: 0 10px;
}

.accordion {
  border-radius: var(--border-radius-small);
  color: var(--color-dark);
  cursor: pointer;
  text-align: left;
  background-color: #ccc;
  border: none;
  outline: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.25rem 2.5rem;
  font-weight: bold;
  transition: background-color .3s;
  display: flex;
}

.accordion i {
  color: var(--color-dark);
}

.accordion.dark i {
  color: var(--color-light);
}

.accordion.dark {
  background-color: var(--color-primary);
  border-radius: var(--border-radius);
  color: var(--color-light);
  padding: 2rem 2.5rem;
}

.accordion.active, .accordion:hover {
  background-color: #ddd;
}

.accordion.dark.active, .accordion.dark:hover {
  background-color: var(--color-primary);
}

.panel {
  padding: 0 2.5rem;
  display: none;
  overflow: hidden;
}

div.navigation-container {
  text-align: center;
  font-family: var(--font-family-primary);
  border-top: 1px solid #c5c5c5;
  border-bottom: 1px solid #c5c5c5;
  width: 100%;
  margin: 20px auto;
  padding: 12px 5px;
  font-size: 110%;
  display: inline-block;
}

div.navigation-container a {
  color: #000;
  padding: 6px;
  font-size: 110%;
  text-decoration: none;
}

div.navigation-container span {
  color: #c50019;
  padding: 6px;
  font-size: 110%;
}

#content table {
  width: 100%;
  margin-bottom: 10px;
  padding: 0 10px;
}

#content table.table-with-header tr:first-child {
  color: #fff;
  background-color: var(--color-primary) !important;
}

#content tr, #content tr:nth-child(odd) {
  background-color: #f3f3f3;
}

#content th, #content td {
  background-color: inherit;
  padding: 15px;
  font-size: 100%;
}

#content td p {
  margin: 0;
  font-size: 100%;
  font-weight: normal;
}

span.input-error {
  color: #c20000;
  margin: 5px;
  display: block;
}

.clear {
  clear: both;
}

#map {
  height: 350px;
}

img.right {
  float: right;
  max-width: 460px;
  margin-bottom: 15px;
  margin-left: 15px;
  height: auto !important;
}

img.left {
  float: left;
  max-width: 460px;
  margin-bottom: 15px;
  margin-right: 15px;
  height: auto !important;
}

img.right.big, img.left.big {
  max-width: 560px;
}

a.video-link {
  display: block;
  position: relative;
}

.video-link:after {
  content: "";
  pointer-events: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 155px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.line-border {
  text-align: center;
  overflow: hidden;
}

.line-border:before, .line-border:after {
  content: "";
  vertical-align: middle;
  background-color: #e7e7e7;
  width: 50%;
  height: 1px;
  display: inline-block;
  position: relative;
}

.line-border:before {
  margin-left: -50%;
  right: .5em;
}

.line-border:after {
  margin-right: -50%;
  left: .5em;
}

.flex {
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  display: flex;
}

:root {
  --grid-gap: 6rem;
  --grid-gap-tablet: 4rem;
  --grid-gap-mobile: 2rem;
}

.grid {
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr var(--grid-gap) 1fr;
  grid-gap: var(--grid-gap);
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 0;
}

.grid.top-align, .grid.left-image.top-align, .grid.right-image.top-align {
  align-items: start !important;
}

.grid.bottom-align, .grid.left-image.bottom-align, .grid.right-image.bottom-align {
  align-items: flex-end !important;
}

.grid.two, .grid.two.wide {
  -ms-grid-columns: 1fr var(--grid-gap) 1fr;
  grid-gap: var(--grid-gap);
  grid-template-columns: 1fr 1fr;
}

.grid.two.sidebar {
  -ms-grid-columns: 2fr var(--grid-gap) 1fr;
  grid-gap: var(--grid-gap);
  grid-template-columns: 2fr 1fr;
}

.grid.two.team {
  -ms-grid-columns: 1fr var(--grid-gap) 2fr;
  grid-gap: var(--grid-gap);
  grid-template-columns: 1fr 2fr;
}

.grid.three {
  -ms-grid-columns: 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr;
  grid-gap: var(--grid-gap);
  grid-template-columns: 1fr 1fr 1fr;
}

.grid.four {
  -ms-grid-columns: 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr;
  grid-gap: var(--grid-gap);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid.five {
  -ms-grid-columns: 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr;
  grid-gap: var(--grid-gap);
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.grid.six {
  -ms-grid-columns: 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr;
  grid-gap: var(--grid-gap);
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid.two.right-image {
  -ms-grid-columns: 1fr var(--grid-gap) 600px;
  grid-gap: 0 var(--grid-gap);
  grid-template-columns: 1fr 600px;
  align-items: center;
}

.grid.two.right-image.hero-image {
  -ms-grid-columns: 1fr 0rem 60%;
  grid-gap: 0;
  grid-template-columns: 1fr 60%;
  align-items: start;
}

.grid.two.left-image {
  -ms-grid-columns: 600px var(--grid-gap) 1fr;
  grid-gap: 0 var(--grid-gap);
  grid-template-columns: 600px 1fr;
  align-items: center;
}

.grid.three.right-image {
  -ms-grid-columns: 1fr var(--grid-gap) 1fr var(--grid-gap) 600px;
  grid-gap: 0 var(--grid-gap);
  grid-template-columns: 1fr 1fr 600px;
  align-items: center;
}

.grid.three.left-image {
  -ms-grid-columns: 600px var(--grid-gap) 1fr var(--grid-gap) 1fr;
  grid-gap: 0 var(--grid-gap);
  grid-template-columns: 600px 1fr 1fr;
  align-items: center;
}

.grid.two.right-image.large {
  -ms-grid-columns: 1fr var(--grid-gap) 2fr;
  grid-gap: 0 var(--grid-gap);
  grid-template-columns: 1fr 2fr;
  align-items: center;
}

.grid.two.left-image.large {
  -ms-grid-columns: 2fr var(--grid-gap) 1fr;
  grid-gap: 0 var(--grid-gap);
  grid-template-columns: 2fr 1fr;
  align-items: center;
}

.grid.three.right-image.large {
  -ms-grid-columns: 1fr var(--grid-gap) 1fr var(--grid-gap) 2fr;
  grid-gap: 0 var(--grid-gap);
  grid-template-columns: 1fr 1fr 2fr;
  align-items: center;
}

.grid.three.left-image.large {
  -ms-grid-columns: 2fr var(--grid-gap) 1fr var(--grid-gap) 1fr;
  grid-gap: 0 var(--grid-gap);
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
}

.grid-item {
  position: relative;
}

.grid-item.span-two {
  grid-column: span 2;
}

.grid-item.span-three {
  grid-column: span 3;
}

.grid-item.span-four {
  grid-column: span 4;
}

.grid-item.span-five {
  grid-column: span 5;
}

.grid-item.no-gap {
  margin: calc(var(--grid-gap) * -1);
}

.grid-item.no-gap-top {
  margin-top: calc(var(--grid-gap) * -1);
}

.grid-item.no-gap-bottom {
  margin-bottom: calc(var(--grid-gap) * -1);
}

.grid.two > .grid-item:first-of-type, .grid.two > div:first-of-type {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}

.grid.two > .grid-item:nth-of-type(2), .grid.two > div:nth-of-type(2) {
  -ms-grid-column: 3;
  -ms-grid-row: 1;
}

.grid.two > .grid-item:nth-of-type(3), .grid.two > div:nth-of-type(3) {
  -ms-grid-column: 1;
  -ms-grid-row: 2;
}

.grid.two > .grid-item:nth-of-type(4), .grid.two > div:nth-of-type(4) {
  -ms-grid-column: 3;
  -ms-grid-row: 2;
}

.grid.two > .grid-item:nth-of-type(5), .grid.two > div:nth-of-type(5) {
  -ms-grid-column: 1;
  -ms-grid-row: 3;
}

.grid.two > .grid-item:nth-of-type(6), .grid.two > div:nth-of-type(6) {
  -ms-grid-column: 3;
  -ms-grid-row: 3;
}

.grid.three > .grid-item:first-of-type, .grid.three > div:first-of-type {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

.grid.three > .grid-item:nth-of-type(2), .grid.three > div:nth-of-type(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}

.grid.three > .grid-item:nth-of-type(3), .grid.three > div:nth-of-type(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}

.grid.three > .grid-item:nth-of-type(4), .grid.three > div:nth-of-type(4) {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
}

.grid.three > .grid-item:nth-of-type(5), .grid.three > div:nth-of-type(5) {
  -ms-grid-row: 2;
  -ms-grid-column: 3;
}

.grid.three > .grid-item:nth-of-type(6), .grid.three > div:nth-of-type(6) {
  -ms-grid-row: 2;
  -ms-grid-column: 5;
}

.grid.three > .grid-item:nth-of-type(7), .grid.three > div:nth-of-type(7) {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}

.grid.three > .grid-item:nth-of-type(8), .grid.three > div:nth-of-type(8) {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
}

.grid.three > .grid-item:nth-of-type(9), .grid.three > div:nth-of-type(9) {
  -ms-grid-row: 3;
  -ms-grid-column: 5;
}

.grid.four > .grid-item:first-of-type, .grid.four > div:first-of-type, .grid.image-list.four > .grid-item:first-of-type {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}

.grid.four > .grid-item:nth-of-type(2) .grid.four > div:nth-of-type(2), .grid.image-list.four > .grid-item:nth-of-type(2) {
  -ms-grid-column: 3;
  -ms-grid-row: 1;
}

.grid.four > .grid-item:nth-of-type(3) .grid.four > div:nth-of-type(3), .grid.image-list.four > .grid-item:nth-of-type(3) {
  -ms-grid-column: 5;
  -ms-grid-row: 1;
}

.grid.four > .grid-item:nth-of-type(4) .grid.four > div:nth-of-type(4), .grid.image-list.four > .grid-item:nth-of-type(4) {
  -ms-grid-column: 7;
  -ms-grid-row: 1;
}

.grid.four > .grid-item:nth-of-type(5) .grid.four > div:nth-of-type(5), .grid.image-list.four > .grid-item:nth-of-type(5) {
  -ms-grid-column: 1;
  -ms-grid-row: 2;
}

.grid.four > .grid-item:nth-of-type(6) .grid.four > div:nth-of-type(6), .grid.image-list.four > .grid-item:nth-of-type(6) {
  -ms-grid-column: 3;
  -ms-grid-row: 2;
}

.grid.four > .grid-item:nth-of-type(7) .grid.four > div:nth-of-type(7), .grid.image-list.four > .grid-item:nth-of-type(7) {
  -ms-grid-column: 5;
  -ms-grid-row: 2;
}

.grid.four > .grid-item:nth-of-type(8) .grid.four > div:nth-of-type(8), .grid.image-list.four > .grid-item:nth-of-type(8) {
  -ms-grid-column: 7;
  -ms-grid-row: 2;
}

.grid.six > .grid-item:first-of-type, .grid.six > div:first-of-type {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

.grid.six > .grid-item:nth-of-type(2), .grid.six > div:nth-of-type(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}

.grid.six > .grid-item:nth-of-type(3), .grid.six > div:nth-of-type(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}

.grid.six > .grid-item:nth-of-type(4), .grid.six > div:nth-of-type(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 7;
}

.grid.six > .grid-item:nth-of-type(5), .grid.six > div:nth-of-type(5) {
  -ms-grid-row: 1;
  -ms-grid-column: 9;
}

.grid.six > .grid-item:nth-of-type(6), .grid.six > div:nth-of-type(6) {
  -ms-grid-row: 1;
  -ms-grid-column: 12;
}

.grid.six > .grid-item:nth-of-type(7), .grid.six > div:nth-of-type(7) {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
}

.grid.six > .grid-item:nth-of-type(8), .grid.six > div:nth-of-type(8) {
  -ms-grid-row: 2;
  -ms-grid-column: 3;
}

.grid.six > .grid-item:nth-of-type(9), .grid.six > div:nth-of-type(9) {
  -ms-grid-row: 2;
  -ms-grid-column: 5;
}

.grid.six > .grid-item:nth-of-type(10), .grid.six > div:nth-of-type(10) {
  -ms-grid-row: 2;
  -ms-grid-column: 7;
}

.grid.six > .grid-item:nth-of-type(11), .grid.six > div:nth-of-type(11) {
  -ms-grid-row: 2;
  -ms-grid-column: 9;
}

.grid.six > .grid-item:nth-of-type(12), .grid.six > div:nth-of-type(12) {
  -ms-grid-row: 2;
  -ms-grid-column: 12;
}

@media (width <= 992px) {
  .grid.two.right-image {
    -ms-grid-columns: 1fr var(--grid-gap-tablet) 300px;
    grid-gap: 0 var(--grid-gap-tablet);
    grid-template-columns: 1fr 300px;
  }

  .grid.two.left-image {
    -ms-grid-columns: 300px var(--grid-gap-tablet) 1fr;
    grid-gap: 0 var(--grid-gap-tablet);
    grid-template-columns: 300px 1fr;
  }

  .grid.three.right-image {
    -ms-grid-columns: 1fr var(--grid-gap-tablet) 1fr var(--grid-gap-tablet) 300px;
    grid-gap: 0 var(--grid-gap-tablet);
    grid-template-columns: 1fr 1fr 300px;
  }

  .grid.three.left-image {
    -ms-grid-columns: 300px var(--grid-gap-tablet) 1fr var(--grid-gap-tablet) 1fr;
    grid-gap: 0 var(--grid-gap-tablet);
    grid-template-columns: 300px 1fr 1fr;
  }

  .grid.four.footer-item-container, .grid.five.footer-item-container {
    -ms-grid-columns: 1fr var(--grid-gap-tablet) 1fr;
    grid-gap: var(--grid-gap-tablet);
    grid-template-columns: 1fr 1fr;
  }
}

@media (width <= 768px) {
  .grid.two, .grid.two.sidebar, .grid.two.left-image, .grid.two.right-image, .grid.two.right-image.large, .grid.two.left-image.large, .grid.three, .grid.three.right-image, .grid.three.left-image, .grid.four, .grid.five, .grid.six {
    -ms-grid-columns: 1fr;
    grid-gap: var(--grid-gap-mobile);
    grid-template-columns: 1fr;
  }

  .grid-item.no-gap {
    margin: calc(var(--grid-gap-mobile) * -1);
  }

  .grid-item.no-gap-top {
    margin-top: calc(var(--grid-gap-mobile) * -1);
  }

  .grid-item.no-gap-bottom {
    margin-bottom: calc(var(--grid-gap-mobile) * -1);
  }

  .strat-grid.grid.two.left-image {
    -ms-grid-columns: 1fr;
    grid-gap: var(--grid-gap-mobile);
    margin-bottom: var(--grid-gap-mobile);
    grid-template-columns: 1fr;
  }

  .grid.four.footer-item-container, .grid.five.footer-item-container {
    -ms-grid-columns: 1fr;
    grid-gap: var(--grid-gap-mobile);
    grid-template-columns: 1fr;
  }
}

.slideshow {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.slides {
  width: 100%;
  height: 100%;
  transition: transition .5s ease-in-out;
  display: flex;
}

.slide {
  box-sizing: border-box;
  flex: 0 0 100%;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  display: flex;
}

.slide-transition {
  transition: margin-left .5s ease-in-out;
}

.slide-content {
  background-color: #add8e6;
  width: 100%;
  height: 100%;
}

.slide-flex {
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 850px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
}

.slide-text {
  text-align: left;
}

.slide-image {
  text-align: center;
  max-height: 100%;
  padding: 4rem 0;
}

.slide-image img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.arrow-button {
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: none;
  font-size: 300%;
  position: absolute;
}

#prevBtn {
  left: 20px;
}

#nextBtn {
  right: 20px;
}

#downBtn {
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  background-image: url("top.3f41adf3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  width: 45px;
  height: 45px;
  margin: 0 auto;
  animation: 2s ease-in-out infinite bob_up_down;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  transform: rotate(180deg);
}

@keyframes bob_up_down {
  0% {
    transform: translateY(0)rotate(180deg);
  }

  50% {
    transform: translateY(-20px)rotate(180deg);
  }

  100% {
    transform: translateY(0)rotate(180deg);
  }
}

#downBtn.active {
  opacity: 1;
  visibility: visible;
}

@media screen and (height <= 800px) {
  #downBtn {
    position: fixed;
    bottom: 20px;
  }
}

.quick-link-container {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin: 4rem 0;
  display: grid;
}

.quick-link-container a.quick-link {
  border-radius: var(--border-radius);
  text-align: center;
  -o-transition: all .2s ease;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5rem;
  transition: all .2s;
  display: flex;
  text-decoration: none !important;
}

.quick-link-container a.quick-link:hover {
  text-decoration: none;
  transform: scale(1.035);
}

.quick-link-container a.quick-link:nth-child(7) {
  grid-column: 2;
}

.quick-link-container a.quick-link.dsbn {
  background-color: #fff;
}

.quick-link-container a.quick-link.school-cash {
  background-color: #0076cf;
}

.quick-link-container a.quick-link.report-absence {
  background-color: #d50037;
}

.quick-link-container a.quick-link.social, .quick-link-container a.quick-link.facebook {
  background-color: #0055b8;
}

.quick-link-container a.quick-link.school-calendar {
  background-color: #fbb03b;
}

.quick-link-container a.quick-link.bell-times {
  background-color: #88c050;
}

.quick-link-container a.quick-link.instagram {
  background: linear-gradient(26deg, #ffd600 0%, #ff0100 50%, #d800b9 100%);
}

.quick-link-container a.quick-link.newsletter-updates {
  background-color: #722289;
}

.quick-link-container a.quick-link.contact-us {
  background-color: #ff8c0e;
}

.quick-link-container a.quick-link.twitter {
  background-color: #231f20;
}

.quick-link-container a.quick-link img {
  margin-bottom: 15px;
  width: auto !important;
  height: 40px !important;
}

.quick-link-container a.quick-link span {
  color: var(--color-light);
  font-size: var(--font-size-h3);
  letter-spacing: 0;
  font-weight: 600;
  line-height: 1em;
  display: block;
}

#content.with-sidebar {
  grid-template-columns: 1fr 300px;
  gap: 6rem;
  display: grid;
}

#content.with-image {
  grid-template-columns: 1fr 1fr;
  gap: 6rem;
  display: grid;
}

#content p, #content ul li, #content ol li {
  font-size: var(--font-size-paragraph-bg);
}

#content a:not(.btn) {
  color: var(--color-primary);
  text-decoration: underline;
}

#content a:not(.btn):hover {
  color: var(--color-secondary);
}

#content .right-content.map iframe {
  border-radius: var(--border-radius);
  border: none;
  width: 100%;
  max-width: 100%;
  display: block;
}

#content .right-content img {
  border-radius: var(--border-radius);
}

#content #sidebar {
  background-color: var(--color-primary);
  border-radius: var(--border-radius);
  padding: 2rem 4rem;
}

#content #sidebar img {
  border-radius: var(--border-radius);
}

#content #sidebar .sidebar-submenu {
  display: none;
}

#content #sidebar .sidebar-link {
  color: var(--color-light);
  padding: 10px 0;
  display: block;
  text-decoration: none !important;
}

#content #sidebar .sidebar-link:not(:last-child) {
  border-bottom: 1px solid var(--color-light);
}

.resource-link-container {
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  display: grid;
}

.resource-link-container a.resource-link {
  border: 4px solid var(--color-primary);
  border-radius: var(--border-radius);
  color: var(--color-dark);
  font-size: var(--font-size-paragraph-bg);
  text-align: center;
  padding: 4rem;
  text-decoration: none !important;
}

.resource-link-container a.resource-link:hover {
  background-color: var(--color-primary) !important;
  color: var(--color-light) !important;
  text-decoration: none !important;
}

.month-grid {
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  display: grid;
}

.month {
  border: 2px solid var(--color-primary);
  border-radius: var(--border-radius);
  color: var(--color-dark);
  font-size: var(--font-size-paragraph-bg);
  text-align: center;
  padding: 3rem;
}

.month-link {
  color: var(--color-dark);
  display: block;
  text-decoration: none !important;
}

.month-link:hover {
  color: var(--color-primary);
}

a.month-link[href="#"], a.month-link[href=""], a.month-link:not([href]) {
  pointer-events: none;
}

a.month-link[href="#"]:hover, a.month-link[href=""]:hover, a.month-link:not([href]):hover {
  color: var(--color-dark);
}

.weekly-links {
  flex-direction: column;
  gap: 5px;
  margin-top: 1rem;
  display: flex;
}

.week-link {
  font-size: var(--font-size-paragraph-sm);
  color: var(--color-dark);
  text-decoration: none !important;
}

.week-link:hover {
  color: var(--color-primary);
}

a.week-link[href="#"], a.week-link[href=""], a.week-link:not([href]) {
  pointer-events: none;
}

a.week-link[href="#"]:hover, a.week-link[href=""]:hover, a.week-link:not([href]):hover {
  color: var(--color-dark);
}

#google-language-container {
  margin-top: 2rem;
}

#staff table {
  width: 100%;
  margin-top: 2rem;
}

#staff table tr {
  padding: 1rem 0;
}

#staff table tr td:first-child, #staff table tr td:nth-child(2) {
  width: 40%;
}

#staff table tr td:nth-child(3) {
  width: 20%;
}

.family-of-schools .school {
  margin-bottom: 3rem;
}

.family-of-schools acronym {
  border: 0;
  font-weight: bold;
  text-decoration: none;
}

.family-of-schools .school .info {
  color: #000;
  margin-left: 5px;
}

.family-of-schools .school .info .website {
  word-break: break-all;
}

.family-of-schools .school .logo {
  float: left;
}

.family-of-schools .school h3, .family-of-schools .school .info {
  margin-left: 100px;
}

@media (width <= 1500px) {
  header nav {
    gap: 2rem;
  }

  header .contact-info, header .contact-info span {
    display: block;
  }
}

@media (width <= 1350px) {
  header .phone-toggle {
    width: 30px;
    height: 30px;
    display: block;
  }

  header .contact-info {
    display: none;
  }

  header .contact-info-mobile {
    display: block;
  }
}

@media (width <= 1100px) {
  .button-container {
    display: none;
  }

  header .button-container-mobile {
    display: flex;
  }
}

@media (width <= 1000px) {
  :root {
    --height-navigation: 250px;
    --font-size-heading-one: 3.6em;
    --font-size-heading-two: 2em;
    --font-size-heading-three: 1.2em;
    --font-size-heading-four: 1.2em;
    --font-size-heading-five: 1em;
    --font-size-paragraph-bg: 1.2em;
  }

  img.right, img.right.big, img.left, img.left.big {
    max-width: 415px;
  }

  img.left, img.left.big {
    margin: 0 15px 15px 0;
  }

  img.right, img.right.big {
    margin: 0 0 15px 15px;
  }

  #title h1.main-title {
    font-size: var(--font-size-heading-one) !important;
  }
}

@media (width <= 768px) {
  :root {
    --width-logo: 150px;
    --height-logo: 110px;
    --height-navigation: 160px;
    --font-size-heading-one: 2.8em;
    --font-size-heading-two: 2em;
    --font-size-heading-three: 1.2em;
    --font-size-heading-four: 1.1em;
    --font-size-heading-five: 1em;
    --font-size-paragraph-bg: 1.1em;
    --padding-section-spacing: 4rem;
    --padding-gap: 2rem;
  }

  img.right, img.right.big, img.left, img.left.big {
    max-width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
    padding: 0;
    float: none !important;
  }

  table {
    max-width: 100%;
    width: 100% !important;
  }

  .content-container .wrapper {
    grid-template-columns: 1fr;
  }

  header .logo {
    top: -1.5rem;
  }

  header .logo span.logo-title h1 {
    margin-top: 1.5rem;
  }

  header .logo span.logo-title h1 span {
    font-size: 3rem;
  }

  header .logo span.logo-title h1 span:nth-child(2) {
    font-size: 2.2rem;
  }

  header .logo span.logo-title h1 span.french-immersion-title {
    font-size: 3rem;
  }

  header .logo span.logo-title h1 span.french-immersion-subtitle {
    font-size: 1.8rem;
  }

  .footer-container {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .footer-column.logo-container.dsbn-logo {
    width: 100%;
  }

  .footer-column {
    width: 48%;
  }

  #content {
    gap: 4rem;
  }

  #content.with-sidebar {
    grid-gap: 2rem;
    grid-template-columns: 1fr !important;
  }

  #content #sidebar {
    order: 1;
    padding: 0;
  }

  #content.with-sidebar .left-content {
    order: 2;
  }

  #content.with-image {
    grid-gap: 2rem;
    grid-template-columns: 1fr !important;
  }

  #content #sidebar .sidebar-submenu {
    display: flex;
  }

  .mobile-panel {
    padding: 0 2.5rem;
    display: none;
    overflow: hidden;
  }

  .quick-link-container {
    margin: 0;
    grid-template-columns: 1fr !important;
  }

  .quick-link-container a.quick-link:nth-child(7) {
    grid-column: 1;
  }

  .resource-link-container {
    margin: 0;
    grid-template-columns: 1fr !important;
  }

  .month-grid {
    grid-template-columns: 1fr 1fr !important;
  }

  #content #sidebar .sidebar-link {
    padding: 2rem 0;
  }

  .calendar_link {
    height: 500px;
  }
}

@media (width <= 550px) {
  :root {
    --font-size-heading-one: 2.4em;
    --font-size-heading-two: 2em;
    --font-size-heading-three: 1.2em;
    --font-size-heading-four: 1.1em;
    --font-size-heading-five: 1em;
    --font-size-paragraph-bg: 1.1em;
    --padding-section-spacing: 4rem;
  }

  header {
    padding: 2rem 0;
  }

  header .wrapper {
    justify-content: space-between;
    height: 100%;
  }

  header .logo {
    max-height: 100%;
    position: static;
    top: 0;
    left: 0;
  }

  header .logo a {
    max-height: 100%;
  }

  header .logo span.logo-title {
    display: none;
  }

  header .logo span.logo-icon {
    height: 80px;
    max-height: 100%;
  }

  #title h1.main-title {
    margin: 2rem 0;
  }

  .quick-link-container a.quick-link {
    padding: 3rem;
  }

  .quick-link-container a.quick-link img {
    height: 30px !important;
  }

  .footer-column.logo-container.dsbn-logo, .footer-column {
    text-align: center;
    width: 100%;
  }

  .footer-column ul li {
    text-align: center;
  }

  .footer-logo, .footer-redefining-logo {
    margin: 0 auto;
  }

  #google-language-container {
    margin-top: 4rem;
  }

  header .contact-info-mobile {
    text-align: center;
    margin: 0 2rem 2rem;
  }

  .button-container-mobile {
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
    margin: 0 2rem 2rem;
  }

  .button-container-mobile .btn {
    text-align: center;
    width: 100% !important;
  }

  .family-of-schools .school {
    margin-bottom: 4rem;
  }

  .family-of-schools .school .logo {
    float: none;
  }

  .family-of-schools .school h3, .family-of-schools .school .info {
    margin-left: 0;
  }
}

/*# sourceMappingURL=404.04a3cd8f.css.map */
